import * as React from "react";
import { useLocalization } from "gatsby-theme-i18n";
import Seo from "../components/seo";
import ModalClose from "../components/modal-close";
import AudioWrapper from "../components/audio-wrapper";

const AudioPage = () => {
  const { locale, config } = useLocalization();
  const { langDir, localName } = config.find((item) => item.code === locale);
  const audioHost =
    "https://www.inspirationalfilms.com/audio/The_Story_of_Jesus_";
  const i18nIds = {
    en: "English_84083",
    fa: "Farsi_Western_84086",
    tk: "Turkmen_84362",
    tr: "Turkish_84361",
    uz: "Uzbek-Northern_84367",
  };
  const url = `${audioHost}${i18nIds[locale]}.mp3`;
  return (
    <main>
      <Seo title={`Audio - ${localName}`}>
        <html lang={locale} dir={langDir} />
      </Seo>
      <ModalClose />
      <AudioWrapper audioUrl={url} />
    </main>
  );
};

export default AudioPage;
