import React from "react";
import * as audioStyles from "./audio-wrapper.module.css";

const AudioWrapper = ({ audioUrl }) => (
  <div className="row">
    <div className={`row-container ${audioStyles.audioRowContainer}`}>
      <audio preload="auto" src={audioUrl} controls>
        <source type="audio/mpeg" src={audioUrl} />
        <a href={audioUrl} target="_blank" rel="noreferrer">
          {audioUrl}
        </a>
      </audio>
    </div>
  </div>
);

export default AudioWrapper;
